.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0 5px 0;
    margin-top: 10px;
    box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 1);
    background-color: var(--primary);
    z-index: 10000;
}

.bottom-nav .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-item span {
    max-height: 20px;
}

.menu-item a {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    transition: .3s;
}

.menu-item a svg {
    min-width: 2rem;
    max-width: 2rem;
}

.menu-item a.active {
    color: rgba(255, 255, 255, 1);
}

.menu-item a.active svg {
    fill: rgba(255, 255, 255, 1);
}

.menu-item a:not(.active) {
    color: rgba(255, 255, 255, .5);
}

.menu-item a:not(.active) svg {
    fill: rgba(255, 255, 255, .5);
}