:root {
  --background: #10120D;
  --foreground: #FFF4E6;
  --primary: #2C7432;
  --secondary: #2C7432;
  --gray: #C1C1C1;
  --active-primary: #FFF4E6;
  --light-red: #FF1B1B;
  --pinky-red: #E24A4A;
  --primary-font: "Roboto", sans-serif;
  --secondary-font: "Roboto Slab", serif;
}

body {
  color: var(--foreground);
  background-color: var(--background);
  font-family: var(--primary-font);
  font-weight: 400;
}

main {
  margin-top: 10px;
  margin-bottom: 100px;
}

.no-scroll {
  overflow: hidden;
}

.container {
  /* max-width: 32.5rem; */
  max-width: 95%;
  margin-right: auto;
  margin-left: auto;
}

/* Home View */

.hero {
  margin-bottom: 10px;
  text-align: center;
}

.daily {
  margin-bottom: 30px;
}

.daily-content {
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
}

.daily-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 50%;
}

.home .cocktail-img {
  width: 100%;
  height: 15rem;
}

.home-list {
  display: flex;
  flex-direction: column;
}

.home-link {
  padding: 30px;
  margin-bottom: 20px;
  color: var(--foreground);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  text-decoration: none;
  text-align: center;
  font-size: 1.5em;
  font-weight: 600;
}

.home-link:active {
  color: var(--primary);
  background-color: var(--background);
  border: 1px solid var(--foreground);
}

.home-link:visited {
  color: var(--foreground);
}

.favorites {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.home .section-suggestions {
  padding-bottom: 38px;
}

.home .suggestions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.home .suggest-text {
  margin-bottom: 10px;
}

@media (min-width: 48em) {

  .daily-info {
    min-width: 30%;
  }

  .home-list {
    flex-direction: initial;
    flex-wrap: wrap;
    gap: 10px;
  }

  .home-link {
    flex: max(350px, 100%/2 - 10px);
    border-radius: 5px;
    transition: ease-in-out all .4s;
  }

  .link-btn:hover,
  .home-link:hover {
    color: var(--foreground);
    background-color: var(--background);
    border: 1px solid var(--foreground);
  }

  .hero {
    margin-bottom: 30px;
  }
}

/* RandomCocktail View */

.btn-random-ctn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.btn-random-ctn .btn {
  flex: max(200px, 100%/4 - 10px);
}

.btn:first-child {
  min-width: 100%;
}

/* Cocktail/:id View */

.cocktail-details {
  font-family: var(--secondary-font);
  font-weight: 400;
}

.cocktail-info div:first-child,
.cocktail-top {
  margin-bottom: 30px;
}

.cocktail-info h2 {
  font-size: 2em;
  line-height: 1;
  margin-bottom: 10px;
}

.cocktail-info div:first-child p {
  font-style: italic;
  font-size: .9em;
}

.cocktail-img {
  width: 100%;
  height: 23rem;
  background-color: var(--foreground);
  border-radius: 5px;
}

.cocktail-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.cocktail-bottom h3 {
  font-size: 2em;
  text-align: center;
  margin-bottom: 30px;
}

.cocktail-bottom *:not(h3) {
  font-family: var(--primary-font);
}

@media (min-width:67.5em) {
  .cocktail-details {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  .cocktail-top {
    margin-bottom: unset;
  }

  .cocktail-top h2,
  .cocktail-bottom h3 {
    font-size: 3em;
  }

  .cocktail-info div:first-child p {
    font-size: 1.5em;
  }

  .cocktail-top {
    min-width: 40%;
  }

  .cocktail-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 50%;
    border-left: 2px solid var(--secondary);
  }

  .cocktail-bottom *:not(h3) {
    font-size: 1.1em;
  }
}

/* Search result */

.result-main .result {
  font-size: 2.8rem;
}