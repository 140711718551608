h1, h2, h3, h4, h5, h6 {
    position: relative;
    font-family: var(--secondary-font);
}

h2.title::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20%;
    height: 2px;
    background-color: var(--primary);
}

h1.title {
    margin-bottom: 3rem;
    font-size: 4.8rem;
}

h2.title {
    margin-bottom: 3rem;
    font-size: 3.2rem;
}

h2.card-title {
    margin-bottom: unset;
}

h3.title {
    font-size: 2.4rem;
    line-height: 1;
}